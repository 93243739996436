import { Info } from "@atoms/text";
import { useStatistics } from "@features/statistics/hooks";
import { ResponsiveLine } from "@nivo/line";
import { useParams } from "react-router-dom";

type LineChartProps = {
  period: "week" | "month" | "year";
};

const LineChart = ({ period, ...props }: LineChartProps) => {
  const { client: clientId } = useParams();
  const { formattedData } = useStatistics(clientId, period);

  if (!formattedData || formattedData.length === 0)
    return (
      <div className="flex justify-center items-center h-full">
        <Info>No data</Info>
      </div>
    );

  return (
    <ResponsiveLine
      {...props}
      margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
      data={[{ id: "Chiffre d'affaires", data: formattedData }]}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendOffset: 36,
        legendPosition: "middle",
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendOffset: -40,
        legendPosition: "middle",
        truncateTickAt: 0,
      }}
      lineWidth={2}
      pointSize={10}
      enableTouchCrosshair={true}
      enablePointLabel={true}
      useMesh={true}
      //   legends={[
      //     {
      //       anchor: "bottom-right",
      //       direction: "column",
      //       justify: false,
      //       translateX: 100,
      //       translateY: 0,
      //       itemsSpacing: 0,
      //       itemDirection: "left-to-right",
      //       itemWidth: 80,
      //       itemHeight: 20,
      //       itemOpacity: 0.75,
      //       symbolSize: 12,
      //       symbolShape: "circle",
      //       symbolBorderColor: "rgba(0, 0, 0, .5)",
      //       effects: [
      //         {
      //           on: "hover",
      //           style: {
      //             itemBackground: "rgba(0, 0, 0, .03)",
      //             itemOpacity: 1,
      //           },
      //         },
      //       ],
      //     },
      //   ]}
    />
  );
};

export default LineChart;
