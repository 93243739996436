import { Page } from "@views/client/_layout/page";

export const NotificationsPage = () => {
  return (
    <Page
      title={[
        {
          label: "Notifications",
        },
      ]}
    >
      Welcome to your Notifications
    </Page>
  );
};
